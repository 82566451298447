import axios from 'axios'
import xss from 'xss'

const state = {
  numOfComponentsReady: 0,
  topics: [],
  posts: [],
  comics: [],
  comicModal: false,
  coimcsModal: false,
  totalDonations: 0
}

const getters = {
  numOfComponentsReady: state => state.numOfComponentsReady,
  topics: state => state.topics,
  createdNewPost: state => state.createdNewPost,
  createdNewComic: state => state.createdNewComic,
  deletedPost: state => state.deletedPost,
  createdNewProfile: state => state.createdNewProfile,
  posts: state => state.posts,
  comics: state => state.comics,
  comic: state => state.comic,
  userProfile: state => state.userProfile,
  tips: state => state.tips,
  getSampleToken: state => state.sampleToken,
  comicModal: state => state.comicModal,
  comicsModal: state => state.comicsModal,
  totalDonations: state => state.totalDonations
}

const actions = {
  setNumOfComponentsReady: async ({ state, commit }) => {
    commit('setNumOfComponentsReady')
  },

  getTopics: async ({ commit }, args) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/get-topics',
        headers: { 'x-access-token': args.token }
      })
      commit('getTopics', response.data)
    } catch (error) {
      console.log(error)
      commit('getTopics', [])
    }
  },

  deleteComic: async ({ commit }, args) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/delete-comic',
        headers: { 'x-access-token': args.token },
        data: {
          comicId: args.comicId
        }
      })
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
  },

  createPost: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/create-post',
        headers: { 'x-access-token': args.token },
        data: {
          comic: args.comicId,
          userid: args.userid,
          username: args.username,
          avatar: args.avatar,
          title: xss(args.title),
          content: xss(args.content),
          images: args.images,
          topic: args.topic
        }
      })
      commit('createdNewPost')
    } catch (error) {
      console.log(error)
    }
  },

  createComic: async ({ commit }, args) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/create-comic',
        headers: { 'x-access-token': args.token },
        data: {
          creator: args.creator,
          cover: args.cover,
          title: args.title,
          issue: args.issue,
          summary: args.summary,
          channel: args.channel,
          minDonation: args.minDonation,
          free: args.free
        }
      })
      commit('createdNewComic', response.data.comicId)
    } catch (error) {
      console.log(error)
    }
  },

  getComics: async ({ commit }, args) => {
    try {
      let totalDonations = 0
      commit('comicsModal', true)
      const response = await axios({
        method: 'post',
        url: '/get-comics',
        headers: { 'x-access-token': args.token },
        data: {
          creator: args.creator
        }
      })

      for (const comic of response.data) {
        for (const suppoter of comic?.supporters) {
          totalDonations += suppoter.amount
        }
      }

      commit('setTotalDonations', totalDonations)
      commit('comicsModal', false)
      commit('getComics', response.data)
    } catch (error) {
      console.log(error)
    }
  },
  // refactor get comic...
  getComic: async ({ commit }, args) => {
    try {
      commit('comicModal', true)
      const response = await axios({
        method: 'post',
        url: '/get-comic',
        headers: { 'x-access-token': args.token },
        data: {
          comicId: args.comicId
        }
      })
      commit('comicModal', false)
      commit('setComic', response.data)
    } catch (error) {
      console.log(error)
      commit('comicModal', false)
    }
  },

  getPosts: async ({ commit }, args) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/get-posts',
        headers: { 'x-access-token': args.token },
        data: { page: args.page }
      })
      console.log('getting posts')
      commit('getPosts', response.data.docs)
    } catch (error) {
      console.log(error)
    }
  },

  searchPosts: async ({ commit }, args) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/search-posts',
        headers: { 'x-access-token': args.token },
        data: {
          searchTerm: args.searchTerm
        }
      })
      commit('getPosts', response.data)
    } catch (error) {
      console.log(error)
    }
  },

  deletePost: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/delete-post',
        headers: { 'x-access-token': args.token },
        data: {
          postId: args.postId
        }
      })
      commit('deletedPost')
    } catch (error) {
      console.log(error)
    }
  },

  createProfile: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/create-profile',
        headers: { 'x-access-token': args.token },
        data: {
          userId: args.userId,
          username: xss(args.username),
          avatar: args.avatar || 'avatar:blank',
          banner: args.banner || 'banner:galaxy',
          topics: args.topics,
          bio: xss(args.bio),
          gender: args.gender,
          age: args.age,
          skillLevel: args.skillLevel
        }
      })
      commit('createdNewProfile', 1)
    } catch (error) {
      console.log(error)
      commit('createdNewProfile', -1)
    }
  },

  getUserProfile: async ({ commit }, args) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/get-profile',
        headers: { 'x-access-token': args.token },
        data: {
          userId: args.userId
        }
      })
      commit('getUserProfile', response.data.user)
    } catch (error) {
      console.log(error)
      commit('getUserProfile', {})
    }
  },

  getSampleUser: async ({ commit }, args) => {
    try {
      const response1 = await axios({
        method: 'post',
        url: '/get-sample-user',
        data: {}
      })
      commit('setSampleToken', response1.data.token)
      try {
        const response2 = await axios({
          method: 'post',
          url: '/get-profile',
          headers: { 'x-access-token': response1.data.token },
          data: {
            userId: response1.data.user
          }
        })
        console.log(response2.data.user)
        commit('getUserProfile', response2.data.user)
      } catch (error) {
        console.log(error)
        commit('getUserProfile', {})
      }
    } catch (error) {
      console.log(error)
    }
  },

  tipPostCreator: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/tips',
        headers: { 'x-access-token': args.token },
        data: {
          postId: args.postId,
          tipper: args.tipper,
          postCreator: args.postCreator
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  follow: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/follow',
        headers: { 'x-access-token': args.token },
        data: {
          profile: args.profile,
          user: args.user
        }
      })

      const response = await axios({
        method: 'post',
        url: '/get-profile',
        headers: { 'x-access-token': args.token },
        data: {
          userId: args.user
        }
      })
      commit('getUserProfile', response.data.user)
    } catch (error) {
      console.log(error)
    }
  },

  unfollow: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/unfollow',
        headers: { 'x-access-token': args.token },
        data: {
          profile: args.profile,
          user: args.user
        }
      })
      const response = await axios({
        method: 'post',
        url: '/get-profile',
        headers: { 'x-access-token': args.token },
        data: {
          userId: args.user
        }
      })
      commit('getUserProfile', response.data.user)
    } catch (error) {
      console.log(error)
    }
  },

  like: async ({ commit }, args) => {
    console.log(args)
    try {
      await axios({
        method: 'post',
        url: '/like',
        headers: { 'x-access-token': args.token },
        data: {
          postId: args.postId,
          userId: args.userId
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  hide: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/hide',
        headers: { 'x-access-token': args.token },
        data: {
          postId: args.postId,
          userId: args.userId
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  createRePost: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/repost',
        headers: { 'x-access-token': args.token },
        data: {
          postId: args.postId,
          userId: args.userId,
          title: args.title
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  postComment: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/post-comment',
        headers: { 'x-access-token': args.token },
        data: {
          parentId: args.parentId,
          userId: args.userId,
          content: args.content,
          avatar: args.avatar,
          username: args.username
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  postReply: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/post-reply',
        headers: { 'x-access-token': args.token },
        data: {
          parentId: args.parentId,
          userId: args.userId,
          content: args.content,
          avatar: args.avatar,
          username: args.username
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}

const mutations = {
  setTotalDonations: (state, totalDonations) => {
    state.totalDonations = totalDonations
  },
  setNumOfComponentsReady: state => {
    state.numOfComponentsReady++
  },
  createdNewComic: (state, comicId) => {
    state.createdNewComic = comicId
  },
  createdNewPost: state => {
    state.createdNewPost++
  },
  deletedPost: state => {
    state.deletedPost++
  },
  getUserProfile: (state, profile) => {
    state.userProfile = profile
  },
  getPosts: (state, posts) => {
    if (posts.length > 0) {
      let newPosts = []
      newPosts.push(state.posts)
      newPosts.push(posts)
      const unique = [...new Map(newPosts.flat().map(item => [item['_id'], item])).values()]
      state.posts = unique
    }
  },
  searchPosts: (state, posts) => {
    state.posts = posts
  },
  getComics: (state, comics) => {
    state.comics = comics
  },
  setComic: (state, comic) => {
    state.comic = comic
  },
  getTopics: (state, topics) => {
    state.topics = topics
  },
  createdNewProfile: (state, response) => {
    state.createdNewProfile += response
  },
  tips: state => {
    state.tips += 100
  },
  setSampleToken: (state, token) => {
    state.sampleToken = token
  },
  comicModal: (state, isOpen) => {
    state.comicModal = isOpen
  },
  comicsModal: (state, isOpen) => {
    state.comicsModal = isOpen
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
